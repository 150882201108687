
@font-face{
  font-family: 'SUIT Variable';
  src:url('./SUIT-Variable-ttf/SUIT-Variable.ttf' );
}

@font-face {
  font-family: 'SUIT Variable';
  src: url('./SUIT-Variable-ttf/SUIT-Variable.ttf');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;

}

.bgd{
  color:'black';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url(./IVC_BG1.gif);
  background-size: 100vh; 
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width:100%;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #bd3232;

}

.App-link {
  color: #61dafb;
}

.mainslogun1{
  font-size:100px;
  font-family : 'SUIT Variable';
  font-weight:1000;

  margin-top:-20px;

  justify-content:center;
  align-items:center;


  animation:animation 3.7s;
}

.mainslogun{
  font-size:50px;
  font-family : 'SUIT Variable';
  font-weight : 1000;

  justify-content:center;
  align-items:center;

  animation:animation1 2.5s;
}

@keyframes animation{
  0%{
      opacity:0;
      transform: translate3d(0, 100%, 0);
  }
  40%{
      opacity:0;
      transform: translate3d(0, 100%, 0);
  }
  100%{
    font-size:100px;
    font-family : 'SUIT Variable';
    font-weight : 1000;
    margin-top:-20px;
    opacity:1;
  
    justify-content:center;
    align-items:center;
    transform: translateZ(0);
  }
}

@keyframes animation1{
  0%{
      opacity:0;
  }
  100%{
    font-size:50px;
    font-family : 'SUIT Variable';
    font-weight : 1000;

    justify-content:center;
    align-items:center;
    transform: translateZ(0);
  }
}

@media(max-width:900px){
  .mainslogun1{
    font-size : 50px;
  }
  .mainslogun{
    font-size:30px;
  }
  @keyframes animation{
    0%{
        opacity:0;
        transform: translate3d(0, 100%, 0);
    }
    40%{
        opacity:0;
        transform: translate3d(0, 100%, 0);
    }
    100%{
      font-size:50px;
      font-family : 'SUIT Variable';
      font-weight : 1000;
      margin-top:-20px;
      opacity:1;
    
      justify-content:center;
      align-items:center;
      transform: translateZ(0);
    }
  }
  
  @keyframes animation1{
    0%{
        opacity:0;
    }
    100%{
      font-size:30px;
      font-family : 'SUIT Variable';
      font-weight : 1000;
  
      justify-content:center;
      align-items:center;
      transform: translateZ(0);
    }
  }
}