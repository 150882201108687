@tailwind base;
@tailwind components;
@tailwind utilities;

/* Color of the links BEFORE scroll */


.navbar-scroll .nav-link,
.navbar-scroll .navbar-toggler .fa-bars {
  background-color: transparent;
}

/* Color of the links AFTER scroll */
.navbar-scrolled .nav-link,
.navbar-scrolled .navbar-toggler .fa-bars {
  background-color: white;
}

/* Color of the navbar AFTER scroll */
.navbar-scrolled {
  background-color: #fff;
}

/* An optional height of the navbar AFTER scroll */
.navbar.navbar-scroll.navbar-scrolled {
  padding-top: 5px;
  padding-bottom: 5px;
}


.hidden-spacer {
    height: 56px
}

.logo{
    padding-top:10px;
    padding-left:10px;
    width:73px;
}
